@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700&display=swap');

$loloft-color-dirty-white: #f3f4f9;
$loloft-color-white: #ffffff;
$loloft-color-gray: #85888c;
$loloft-color-light-black: #1d1919;
$loloft-color-light-black2: #1e1919;
$loloft-color-light-black3: #1c1919;

$loloft-color-not-active: #84888c;
$loloft-color-active: #ffc318;

// Rewriting variables
$loloft-yellow: #ffc100;
$loloft-yellow-dark: #F8B700;
$loloft-dark: #1d1919;
$loloft-pale: #f3f4f9;
$loloft-white: #ffffff;
$loloft-sand: #85878d;
$loloft-red: #ec4639;

// Fonts
$loloft-font: 'Nunito Sans', sans-serif;
$loloft-sm: 13px;
$loloft-fs: $loloft-sm + 3;
$loloft-e: $loloft-sm + 5;
$loloft-r: $loloft-e * 2;
$loloft-lg: 48px;
$loloft-t: 24px;
// Interactions
$loloft-transition: all .3s ease-in-out;

$loloft-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
$loloft-dropdown-shadow: 0px 10px 20px rgba(183, 192, 213, 0.15);

// Styiling the range input
$track-color: #dbdeec !default;
$thumb-color: $loloft-yellow !default;

$thumb-radius: 0 !default;
$thumb-height: 23px !default;
$thumb-width: 23px !default;
$thumb-shadow-size: 0 !default;
$thumb-border-width: 0 !default;

$track-width: 225px !default;
$track-height: 7px !default;
$track-shadow-size: 1px !default;
$track-shadow-blur: 1px !default;
$track-shadow-color: rgba(0, 0, 0, .2) !default;
$track-border-width: 0 !default;
$track-border-color: #cfd8dc !default;

$track-radius: 5px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

// @media only screen and (max-width: 768px){}

html,
body {
  * {
    font-family: $loloft-font;
  }
  background-color: $loloft-color-dirty-white;
  height: 100%;
  margin: 0;
}
body {
  &.pushable {
    background: $loloft-pale !important;
    #root {
      display: block !important;
      width: 100%;
      height: 100%;
      transition: $loloft-transition;
    }
    .pusher {
      transition: $loloft-transition;
    }
  }
}
h1 {
  font-size: $loloft-lg;
  @media only screen and (max-width: 768px) {
    font-size: $loloft-r;
  }
}
h2 {
  font-size: $loloft-r;
  @media only screen and (max-width: 768px) {
    font-size: $loloft-t;
  }
}
h3 {
  font-size: $loloft-t;
}
h4 {
  font-size: $loloft-fs;
}
p {
  font-size: $loloft-fs;
  line-height: 1.5;
  color: $loloft-sand;
}
a {
  color: $loloft-dark;
  &:hover {
    color: $loloft-sand;
  }
}
.section {
  padding-top: 50px;
  padding-bottom: 50px;
  @media only screen and (max-width: 768px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &-title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.5;
    color: $loloft-color-light-black;
    text-align: center;
    @media only screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 33px;
    }
  }
  &-info {
    color: $loloft-color-gray;
    margin-top: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.4;
    padding-bottom: 40px;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      line-height: 1.5;
      margin-top: 15px;
      padding-bottom: 30px;
    }
  }
}
@mixin caret-down($color) {
  content: url('data:image/svg+xml;charset=UTF-8, <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" version="1.1"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#{$color}"/></svg>') !important;
}
/* Defining Loloft Buttons */
.ui {
  &.container {
    &.pushed {
      &.left {
        padding-left: 25px;
        @media only screen and (max-width: 768px) {
          padding-left: 0;
          width: 100% !important;
          .ui {
            &.grid {
              &.stackable {
                margin-left: 0 !important;
                margin-right: 0 !important;
              }
            }
          }
        }
      }
    }
    &.fluid {
      margin-left: 25px !important;
      margin-right: 25px !important;
    }
  }
  &.grid {
    > .row > [class*="three wide"].column,
    > .column.row > [class*="three wide"].column,
    > [class*="three wide"].column,
    &column > [class*="three wide"].column {
      width: 33.333% !important;
    }
  }
  @media only screen and(max-width: 768px) {
    &.grid {
      > .row > [class*="three wide"].column,
      > .column.row > [class*="three wide"].column,
      > [class*="three wide"].column,
      &column > [class*="three wide"].column {
        width: 100% !important;
      }
    }
  }
  &.message {
    &.error {
      p {
        font-size: $loloft-sm;
        color: $loloft-white;
      }
    }
  }
  &.button {
    border-radius: 0;
    white-space: nowrap;
    &.primary {
      background: $loloft-yellow;
      color: $loloft-dark;
      font-size: $loloft-fs;
      padding: 17px 30px;
      transition: $loloft-transition;
      flex-shrink: 0;
      &:hover, &:active, &:focus {
        background: $loloft-yellow-dark;
        color: $loloft-dark;
      }
    }
    &.white {
      background: $loloft-white;
      box-shadow: $loloft-dropdown-shadow;
      font-size: $loloft-sm;
    }
  }
  &.dropdown {
    box-shadow: $loloft-dropdown-shadow;
    background: $loloft-white;
    & > .dropdown {
      &.icon {
        transition: $loloft-transition;
        top: 50%;
        margin-top: -19px;
        right: 20px;
        &:before {
          @include caret-down('%2385878d');
          background-size: 18px 18px;
          height: 18px;
          width: 18px;
        }
      }
    }
    .text {
      padding: 9px 35px 8px 15px;
    }
    &.active {
      & > .dropdown {
        &.icon {
          transform: rotate(180deg);
          &:before {
            @include caret-down('%231d1919');
          }
        }
      }
      .menu {
        border-radius: 0;
        border: 0;
        box-shadow: $loloft-dropdown-shadow;
        margin: 0;
        & > .item {
          padding: 13px 20px !important;
          border: 0;
          font-size: $loloft-fs;
          &:hover, &.selected {
            background: $loloft-pale;
          }
        }
      }
    }
    &.selection {
      border-radius: 0;
      border: 0;
      padding: 35px 20px 15px 20px;
      &.search {
        input {
          &.search {
            padding: 33px 20px 15px 20px;
          }
        }
        & > .text {
          font-size: $loloft-fs;
          padding: 0;
        }
      }
      & > .dropdown {
        &.icon {
          transition: $loloft-transition;
          top: 50%;
          margin-top: -19px;
          right: 20px;
          &:before {
            @include caret-down('%2385878d');
            background-size: 18px 18px;
            height: 18px;
            width: 18px;
          }
        }
      }
    }
  }
  &.sidebar {
    &.right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 300px;
      top: 30px;
      bottom: 30px;
      padding-bottom: 20px;
      height: auto !important;
      &.push {
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
        border: 0;
      }
      &:after {
        display: none;
      }
    }
    &.menu {
      padding-top: 70px;
      .item {
        text-align: right;
        padding: 0;
        &:before, &:after {
          display: none;
        }
        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          font-size: $loloft-fs;
          color: rgba($loloft-dark, .6);
          transition: $loloft-transition;
          padding: 15px 50px 15px 30px;
        }
        .art {
          display: flex;
          width: 45px;
          justify-content: flex-end;
          opacity: .9;
          padding-right: 3px;
        }
        &.active {
          color: $loloft-dark;
          position: relative;
          &:after {
            content: '';
            display: block;
            position: absolute;
            right: 25px;
            top: 50%;
            margin-top: -5px;
            width: 12px;
            height: 12px;
            background: $loloft-yellow;
            border-radius: 50%;
            z-index: 2;
          }
          .art {
            opacity: 1;
          }
        }
      }
      .main {
        .item {

        }
      }
      .separated {
        margin-top: 20px;
        padding-top: 20px;
        position: relative;
        &:before {
          content: '';
          height: 1px;
          background: rgba($loloft-dark, .15);
          position: absolute;
          right: 50px;
          left: 50px;
          top: 0;
        }
      }
      .smaller {
        .item {
          a {
            font-size: $loloft-sm;
            padding: 12px 15px 12px 15px;
          }
        }
      }
    }
    &.visible {
      z-index: 1000;
      & + .pusher {
        overflow: hidden;
        height: 100% !important;
        &:after {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          content: '';
          background: rgba($loloft-sand, .7);
          z-index: 900;
        }
      }
    }
    .icon {
      &.close {
        position: absolute;
        width: 30px;
        height: 30px;
        background: red;
        right: 20px;
        top: 20px;
        background: url("/assets/icons/icon-close.svg") 50% no-repeat;
        background-size: 16px;
        opacity: .5;
      }
    }
  }
  &.menu {
    &.fixed {
      position: absolute;
      padding: 0 5px;
    }
  }
  &.input {
    & > input {
      font-family: $loloft-font;
      border-radius: 0;
      border: 0;
      padding: 15px 17px;
    }
    &.action {
      box-shadow: $loloft-dropdown-shadow;
      button {
        border-radius: 0 !important;
      }
    }
  }
  &.grid {
    &.negative {
      margin: 0;
      .ui {
        &.grid {
          @media only screen and (max-width: 768px) {
            &.stackable {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
  &.label {
    font-size: $loloft-sm;
    padding: 6px 10px;
    line-height: 1;
    border-radius: 0;
    &.yellow {
      color: $loloft-dark !important;
    }
    // checkbox inside?
    .ui {
      &.checkbox {
        label {
          padding: 0 !important;
          margin: 0;
          font-size: $loloft-sm;
          background: transparent;
          &:before {
            background: transparent;
            border-radius: 0;
            border: 0;
          }
        }
      }
    }
  }
  &.search {
    & > .results {
      border-radius: 0;
      border: 0;
      box-shadow: $loloft-dropdown-shadow;
      left: -25px;
      width: 400px;
      padding: 0 0 10px;
      .result {
        border: 0;
        color: $loloft-dark;
        padding: 15px 20px 15px 60px;
        font-weight: 400;
      }
    }
  }
  &.modal {
    background: $loloft-pale;
    box-shadow: $loloft-dropdown-shadow;
    border-radius: 0;
    width: 750px;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    .header {
      font-family: $loloft-font;
      font-size: $loloft-t;
      border: 0;
      padding: 20px 30px !important;
      background: transparent;
    }
    & > .content {
      padding: 0 30px 20px !important;
      border-radius: 0;
      background: transparent;
      @media only screen and (max-width: 768px) {
        padding: 0 30px 30px !important;
      }
    }
    &.filters {
      &.dates {
        & > .content {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .ui {
        &.grid {
          margin-bottom: 0;
          &.negative {
            @media only screen and (max-width: 768px) {
              margin-left: -1rem !important;
              margin-right: -1rem !important;
            }
          }
        }
      }
      .value {
        font-size: $loloft-fs;
        font-weight: 700;
      }
    }
    & > .close {
      background: url("/assets/icons/icon-close.svg") 50% no-repeat;
      background-size: 16px;
      width: 36px;
      height: 36px;
      top: 8px;
      right: 8px;
      padding: 0;
      margin: 0;
      opacity: .3;
      transition: $loloft-transition;
      @media only screen and (max-width: 768px) {
        top: 12px !important;
        right: 12px !important;
      }
      &:hover {
        opacity: .7;
      }
      &:before {
        display: none;
      }
    }
    .box {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.dates {
      & > .content {
        @media only screen and (max-width: 768px) {
          padding: 0 15px 30px !important;
        }
      }
    }
  }
  &.dimmer {
    background-color: rgba($loloft-dark, .2);
  }
  &.popup {
    border: 0;
    box-shadow: $loloft-box-shadow;
    border-radius: 0;
    padding: 20px;
    line-height: 1.5;
  }
  &.checkbox {
    label {
      background: $loloft-white;
      box-shadow: $loloft-dropdown-shadow;
      padding: 10px 15px !important;
      margin-right: 7px;
      margin-bottom: 7px;
      font-size: $loloft-fs;
      &:before, &:after {
        display: none;
      }
    }
    input {
      &:checked {
        & ~ label {
          background: $loloft-yellow;
        }
      }
    }
    &.full {
      background: none;
      label {
        background: none;
        box-shadow: none;
        padding-left: 50px !important;
        margin-top: 5px;
        margin-bottom: 5px;
        &:before {
          content: '';
          display: block;
          width: 34px;
          height: 34px;
          background: $loloft-white;
          box-shadow: $loloft-dropdown-shadow;
          border: 0;
          border-radius: 0;
        }
      }
      input {
        &:checked {
          & ~ label {
            background: none; 
            &:before {
              background: $loloft-yellow;
            }
            &:after {
              display: block;
              content: '';
              background: url("/assets/icons/icon-check.svg") 50% no-repeat;
              background-size: contain;
              width: 24px;
              height: 24px;
              top: 6px;
              left: 4px;
            }
          }
        }
      }
      & + .block {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  &.form {
    .field {
      background: $loloft-white;
      padding: 0;
      box-shadow: $loloft-dropdown-shadow;
      & > label {
        text-align: left;
        font-size: $loloft-sm;
        font-weight: 700;
        padding: 8px 20px 0;
        margin: 0;
      }
      .input {
        input {
          border: 0;
          background: none;
          padding: 8px 20px 9px;
          font-size: $loloft-fs;
          box-shadow: 0 0 0 0 $loloft-yellow;
          transition: $loloft-transition;
          border-radius: 0;
          border-bottom: 2px solid transparent;
          &:focus, &:active {
            border-bottom: 2px solid $loloft-yellow !important;
          }
          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 100px white inset!important;
            box-shadow: 0 0 0 100px white inset!important;
            border-color: white!important;
            &:focus, &:active {
              border-bottom: 2px solid $loloft-yellow !important;
            }
          }
        }
      }
      &.error {
        .input {
          input {
            border-color: $loloft-red;
          }
        }
        & > label {
          color: $loloft-red;
        }
      }
    }
    .fields {
      &.equal {
        .field {
          margin-left: .5em;
          margin-right: .5em;
        }
      }
    }
  }
  &.message {
    font-size: $loloft-sm;
    padding: 10px 15px;
    box-shadow: $loloft-dropdown-shadow;
    border-radius: 0;
    &.error {
      background: $loloft-red;
      color: $loloft-white;
      box-shadow: $loloft-dropdown-shadow;
    }
  }
  &[class*="left icon"] {
    &.input {
      & > input {
        padding-left: 52px !important;
      }
    }
  }
}
.on-white {
  .ui {
    &.dropdown {
      background: $loloft-pale;
      box-shadow: none;
    }
  }
}
.grid {
  &.abstract {
    column-width: 460px;
    column-gap: 15px;
    width: 100%;
    max-width: 1127px;
    margin: 0 auto;
    figure {
      margin: 0 2px 15px;
      display: inline-block;
      column-break-inside: avoid;
      position: relative;
      .meta {
        position: absolute;
        background: linear-gradient(180deg, rgba(36, 31, 32, 0) 0%, rgba(22, 22, 22, 0.7) 100%);
        padding: 30px;
        left: 0;
        right: 0;
        bottom: 0;
        top: 50%;
        color: $loloft-white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
      }
    }
  }
  &.wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
  }
}
.loloft {
  &-popup {
    padding: 25px;
    background: $loloft-white;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
    position: absolute;
    z-index: 1000;
    p {
      padding: 0 0 15px;
      margin: 0;
    }
    small {
      opacity: .6;
      line-height: 1.6;
      display: block;
    }
  }
  &-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -10px;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
    .flex {
      flex: 0 0 50%;
      padding: 5px 10px;
      .inline {
        justify-content: flex-end;
        padding: 0;
      }
    }
  }
  &-progress {
    span {
      display: block;
      font-size: $loloft-sm;
      color: $loloft-sand;
      padding-bottom: 5px;
      font-weight: 700;
    }
    .ui {
      &.progress {
        height: 10px;
        border-radius: 25px;
        overflow: hidden;
        background: $loloft-white;
        .bar {
          background: $loloft-yellow;
          height: 10px;
          border-radius: 25px;
          .progress {
            display: none;
          }
        }
      }
    }
  }
  &-pi {
    padding: 20px;
    position: relative;
    &.card {
      margin-bottom: 15px;
      &.selected {
        box-shadow: $loloft-dropdown-shadow, inset 0 0 0 5px $loloft-yellow;
      }
    }
    label {
      color: $loloft-sand;
      font-size: $loloft-sm;
      display: block;
      padding-bottom: 5px;
    }
    h3 {
      font-size: $loloft-sm;
      font-weight: 700;
      margin: 0;
    }
    &-number {
      padding-bottom: 20px;
      h3 {
        font-size: $loloft-t;
      }
      & + .loloft-pi-number {
        padding-bottom: 0;
      }
    }
    &-actions {
      position: absolute;
      right: 20px;
      top: 15px;
      font-size: $loloft-sm;
      a {
        color: $loloft-red;
      }
    }
  }
  &-timeline {
    width: 375px;
    position: absolute;
    right: 50%;
    left: auto;
    margin-right: -700px;
    z-index: 90;
    padding: 25px;
    top: 50%;
    margin-top: -190px;
    box-shadow: $loloft-box-shadow !important;
    &.card {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-start;
      padding-bottom: 20px;
      position: relative;
      width: 100%;
      &:after {
        content: '';
        width: 2px;
        background: #dbdbdd;
        position: absolute;
        left: 16px;
        margin-left: -1px;
        top: 0;
        bottom: 0;
      }
      &:last-child {
        padding-bottom: 0;
        &:after {
          display: none;
        }
      }
      &-prefix {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 2px solid #dbdbdd;
        background: $loloft-white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-right: 15px;
        z-index: 90;
        img {
          width: 18px;
          opacity: .3;
        }
        &-inside {
          padding-bottom: 0;
          .row {
            padding-bottom: 8px;
            &:after {
              display: none;
            }
            &-info {
              padding-left: 0;
            }
          }
        }
      }
      &-contents {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 5px;
        position: relative;
        font-size: $loloft-fs;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 16px;
          height: 1px;
          background: $loloft-sand;
          z-index: 1;
          opacity: .4;
        }
      }
      small {
        color: $loloft-sand;
        display: block;
        padding-top: 5px;
        line-height: 1.4;
        padding-right: 15px;
      }
      label {
        padding-right: 15px;
        background: $loloft-white;
        position: relative;
        z-index: 2;
        text-transform: capitalize;
      }
      strong {
        padding-left: 15px;
        background: $loloft-white;
        position: relative;
        z-index: 2;
      }
      &-info {
        padding-left: 48px;
        padding-bottom: 15px;
        margin-top: -7px;
        .ui {
          &.label {
            margin: 0 15px 0 0;
            flex: none;
          }
        }
        .commitment {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }
  }
}
.card {
  background: $loloft-white;
  box-shadow: $loloft-dropdown-shadow;
  font-size: $loloft-fs;
  margin-bottom: 2rem;
  transition: $loloft-transition;
  @media only screen and (max-width: 768px) {
    margin: 0 10px 20px;
  }
  &-meta {
    padding: 15px 20px;
  }
  &-price {
    font-size: $loloft-e;
    font-weight: 700;
    padding: 0 0 8px;
    span {
      color: $loloft-sand;
      font-weight: 400;
      font-size: $loloft-sm;
    }
  }
  &-lspace {
    color: $loloft-sand;
    padding: 0 0 8px;
    span {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  &-address {
    color: $loloft-dark;
    font-weight: 700;
  }
  .label {
    float: right;
    margin: 10px 0 0;
  }
  &:hover {
    box-shadow: $loloft-box-shadow;
  }
  &.commitment {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: $loloft-dropdown-shadow;
    margin-bottom: 10px;
    padding: 12px 20px 12px 15px;
    @media only screen and (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
    }
    .contents {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      h4 {
        font-weight: 400;
        padding: 2px 0 0;
        margin: 0;
      }
      span {
        font-size: $loloft-sm;
        color: $loloft-sand;
        display: block;
      }
    }
    .ui {
      &.checkbox {
        &.radio {
          border: 0;
          width: 36px;
          height: 36px;
          margin-top: -2px;
          input {
            width: 36px;
            height: 36px;
            &:checked {
              & ~ label {
                background: transparent;
                &:before {
                  background: $loloft-yellow;
                }
                &:after {
                  content: '';
                  width: 24px;
                  height: 24px;
                  background: transparent url("/assets/icons/icon-check.svg") 50% no-repeat;
                  background-size: contain;
                  top: 50%;
                  left: 50%;
                  transform: unset;
                  margin-left: -7px;
                  margin-top: -1px;
                  background-color: transparent;
                }
              }
            }
          }
          label {
            background: transparent;
            &:before, &:after {
              width: 36px;
              height: 36px;
              border: 0;
              border-radius: 0;
              display: block;
            }
            &:before {
              background: rgba($loloft-dark, .1);
            }
            &:after {
              background-color: transparent;
            }
          }
        }
      }
    }
    .image {
      &.info {
        opacity: .3;
        transition: $loloft-transition;
        &:hover {
          opacity: .7;
        }
      }
    }
  }
  &.upload {
    margin-bottom: 15px;
    .contents {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 150px;
      position: relative;
      cursor: pointer;
      .ui {
        &.image {
          max-height: 100%;
        }
      }
      &.faded {
        .icon, label {
          opacity: 0;
        }
      }
    }
    .icon {
      opacity: .5;
      transition: $loloft-transition;
    }
    label {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10px;
      text-align: center;
      font-size: $loloft-fs;
      color: $loloft-dark;
      font-weight: 700;
      &.hidden {
        display: none;
      }
    }
  }
}
.property {
  &.images {
    .ui {
      &.grid {
        margin: 0;
        & > .row {
          padding: 0;
        }
      }
    }
    .pagination {
      bottom: 10px;
    }
  }
}
.nopad {
  padding: 0 !important;
}
.headline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  @media only screen and (max-width: 768px) {
    padding: 0 25px 5px;
  }
  h3 {
    padding: 0;
    margin: 0;
    @media only screen and (max-width: 768px) {
      font-size: $loloft-fs;
    }
  }
}
// Datepicker
.react {
  &-datepicker {
    font-family: $loloft-font;
    border: 0;
    border-radius: 0;
    width: 100%;
    background: transparent;
    &__month-container {
      float: none;
    }
    &__header {
      background: transparent;
      border: 0;
      padding: 0;
    }
    &__day-name,
    &__day,
    &__time-name {
      width: 36px;
      height: 36px;
      border-radius: 0;
      font-size: $loloft-sm;
      line-height: 36px;
    }
    &__day {
      &--selected,
      &--in-selecting-range,
      &--in-range {
        background: $loloft-yellow;
        color: $loloft-dark;
        &:hover {
          background: $loloft-yellow-dark;
        }
      }
    }
    &__day-names,
    &__week {
      .react {
        &-datepicker {
          &__day-name {
            font-weight: 700;
            font-size: $loloft-fs;
          }
        }
      }
    }
    &__month {
      padding-top: 0;
      margin-top: 0;
    }
    &__current-month,
    &-time__header {
      background: $loloft-white;
      padding: 10px 15px 9px;
      box-shadow: $loloft-dropdown-shadow;
      margin-bottom: 10px;
    }
    &__navigation {
      top: 12px;
    }
  }
}
.flex {
  display: flex;
  &.inline {
    flex-direction: row;
    align-items: center;
    &.spaced {
      justify-content: space-between;
    }
    &.list {
      align-items: flex-start;
      padding-bottom: 20px;
      .content {
        padding-left: 20px;
        padding-top: 3px;
        h4 {
          margin-bottom: 5px;
        }
      }
    }
  }
}
.incremented {
  .ui {
    &.button {
      width: 36px;
      height: 36px;
      background: $loloft-white;
      box-shadow: $loloft-dropdown-shadow;
      padding: 0;
      margin: 0 0 0 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}
// Range styles
@mixin track {
  cursor: default;
  height: $track-height;
  transition: all .2s ease;
  width: $track-width;
  background: $track-color;
}

@mixin thumb {
  background: $thumb-color;
  border: $thumb-border-width;
  border-radius: $thumb-radius;
  box-sizing: border-box;
  cursor: default;
  height: $thumb-height;
  width: $thumb-width;
}

[type='range'] {
  -webkit-appearance: none;
  background: transparent;
  margin: $thumb-height / 3 0;
  width: $track-width;
  margin-right: 20px;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: lighten($track-color, $contrast);
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: lighten($track-color, $contrast);
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;
    background: $track-color;
    border: $track-border-width;
    border-radius: 0;
    &:focus, &:active {
      background: $track-color;
    }
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: ((-$track-border-width * 2 + $track-height) / 2 - $thumb-height / 2);
      }

  &::-moz-range-track {
    @include track;
    background: $track-color;
    border: $track-border-width;
    border-radius: $track-radius;
    height: $track-height / 2;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: ($thumb-height / 2) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: $ie-bottom-track-color;
    border: $track-border-width;
    border-radius: ($track-radius * 2);
  }

  &::-ms-fill-upper {
    background: $track-color;
    border: $track-border-width;
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb;
    margin-top: $track-height / 4;
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }
}
.actions {
  a {
    color: $loloft-dark;
  }
  &.right {
    text-align: right;
    .ui {
      &.button {
        margin-left: 20px;
        margin-bottom: 0;
        min-width: 200px;
      }
    }
  }
}
.mobile {
  &-negative {
    &.ui {
      &.grid {
        @media only screen and (max-width: 768px) {
          margin: 0;
        }
      }
    }
    .ui {
      &.container {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
}
.box {
  &.amenity {
    background: $loloft-pale;
    height: 100px;
    flex: 0 0 15%;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 5px;
    @media (max-width: 1200px) {
      flex: 0 0 18%;
    }
    @media (max-width: 991px) {
      flex: 0 0 22%;
    }
    @media (max-width: 991px) {
      flex: 0 0 22%;
    }
    @media (max-width: 680px) {
      flex: 0 0 30%;
      background: $loloft-white;
    }
    span {
      display: block;
      padding-top: 10px;
      text-align: center;
    }
  }
  &.map {
    margin-bottom: 50px;
  }
  &.images {
    padding-top: 15px;
    h4 {
      padding-bottom: 0;
      margin-bottom: 5px;
    }
  }
}
.image {
  &-spaced {
    align-items: center;
    &.right {
      padding-top: 5px;
      padding-bottom: 5px;
      img {
        margin-right: 10px;
      }
    }
  }
}
.padded {
  &.top {
    padding-top: 25px;
  }
}
.more {
  color: $loloft-sand;
  transition: $loloft-transition;
  cursor: pointer;
  img {
    opacity: .6;
    transition: $loloft-transition;
  }
  &:hover {
    color: $loloft-dark;
    img {
      opacity: 1;
    }
  }
}
.aligned {
  &.right {
    text-align: right;
  }
  &.center {
    text-align: center;
  }
}
.dummy {
  margin-bottom: 1em;
  .contents {
    padding-left: 15px;
  }
  label {
    font-size: $loloft-sm;
    padding: 6px 0;
    line-height: 1;
    border-radius: 0;
    font-weight: 700;
  }
  p {
    color: $loloft-dark;
  }
}
.inputs {
  &.four {
    display: flex;
    flex-direction: row;
    margin-left: -5px;
    margin-right: -5px;
    padding-top: 20px;
    padding-bottom: 20px;
    .ui {
      &.input {
        width: 25%;
        margin: 0 5px;
      }
    }
  }
  .ui {
    &.input {
      box-shadow: $loloft-dropdown-shadow;
      display: flex;
      input {
        font-size: $loloft-r;
        font-weight: 700;
        padding: 20px 0 15px;
        text-align: center;
        width: 100%;
        &:focus {
          box-shadow: 0 3px 0 0 $loloft-yellow;
          transform: scale(1.15);
        }
      }
    }
  }
}
i.icon {
  &.mail,
  &.lock,
  &.phone,
  &.user {
    &:before {
      content: '' !important;
    }
    &:after {
      width: 21px;
      height: 21px;
      content: '';
      left: 11px !important;
      margin-top: -12px !important;
      opacity: .5;
    }
  }
  &.mail {
    &:after {
      background: transparent url("/assets/icons/icon-mail.svg") 50% no-repeat;
      background-size: contain;
    }
  }
  &.lock {
    &:after {
      background: transparent url("/assets/icons/icon-lock.svg") 50% no-repeat;
      background-size: contain;
    }
  }
  &.phone {
    &:after {
      background: transparent url("/assets/icons/icon-phone.svg") 50% no-repeat;
      background-size: contain;
    }
  }
  &.user {
    &:after {
      background: transparent url("/assets/icons/icon-user.svg") 50% no-repeat;
      background-size: contain;
    }
  }
}
.suffix {
  position: relative;
  .value {
    position: absolute;
    right: 15px;
    bottom: 10px;
    z-index: 2;
    opacity: .5;
  }
}
.only {
  &.mobile {
    display: none !important;
    @media only screen and (max-width: 768px) {
      display: block !important;
    }
  }
  &.desktop {
    display: block !important;
    @media only screen and (max-width: 768px) {
      display: none !important;
    }
  }
}
