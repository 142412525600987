@import "loloft.scss";

.loloft {
  &-property {
    background: $loloft-white;
    font-size: $loloft-fs;
    &.pushable {
      background: $loloft-white !important;
      .pusher {
        background: $loloft-white;
        @media only screen and (max-width: 768px) {
          background: $loloft-pale;
        }
      }
    }
    .navbar {
      .ui {
        &.menu {
          .item {
            a {
              color: $loloft-white;
            }
          }
        }
      }
    }
    .box {
      padding-bottom: 30px;
      &.pale {
        padding: 30px;
        background: $loloft-pale;
        .image {
          &-spaced {
            padding-top: 8px;
            padding-bottom: 8px;
          }
        }
        .ui {
          &.button {
            margin: 0;
            width: 100%;
          }
        }
      }
      &.special {
        &.mobile {
          .ui {
            &.button {
              position: fixed;
              left: 0;
              width: 100%;
              bottom: 0;
              z-index: 900;
            }
          }
          .box {
            &:last-child {
              padding-bottom: 0;
            }
          }
          .row {
            .box {
              padding-bottom: 0;
            }
          }
          h3, p {
            padding-bottom: 7px;
            margin-bottom: 0;
          }
          .pushed {
            &.top {
              padding-top: 10px;
              span {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                .label {
                  margin: 0 0 5px 5px;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
    .carousell {
      .arrow {
        position: absolute;
        top: 50%;
        margin-top: -30px;
        padding: 10px;
        transition: $loloft-transition;
        cursor: pointer;
        &:hover {
          background: rgba($loloft-dark, .25);
        }
        &.prev {
          left: 30px;
          @media only screen and (max-width: 768px) {
            left: 10px;
          }
        }
        &.next {
          right: 30px;
          @media only screen and (max-width: 768px) {
            right: 10px;
          }
        }
      }
    }
    .ui {
      &.modal {
        & > .content {
          @media only screen and (max-width: 768px) {
            padding: 0 15px 30px !important;
          }
        }
      }
    }
    .list {
      display: grid;
      grid-column-gap: 25px;
      grid-row-gap: 0;
      grid-template-columns: 1fr 1fr;
      @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        & + .more {
          padding-left: 25px;
          padding-right: 25px;
        }
        .card {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
.slider {
  position: relative;
}
.property {
  &-price {
    font-size: $loloft-t;
    font-weight: 700;
    padding-right: 10px;
    span {
      font-size: $loloft-fs;
      color: $loloft-sand;
      font-weight: 400;
    }
  }
  &-hero {
    margin-bottom: 30px;
    @media only screen and (max-width: 768px) {
      margin-left: -25px;
      margin-right: -25px;
    }
  }
  &-actions {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 20;
    span {
      background: rgba($loloft-dark, .3);
      display: inline-block;
      padding: 6px 20px;
      line-height: 0;
      margin-left: 10px;
      transition: $loloft-transition;
      cursor: pointer;
      &:hover {
        background: rgba($loloft-dark, .6);
      }
    }
  }
}
.breakdown {
  .ui {
    &.dropdown {
      &.selection {
        box-shadow: none;
        background: $loloft-pale;
      }
    }
  }
  .loloft {
    &-timeline {
      position: static;
      margin: 0;
      padding: 10px 0 0;
      width: 320px;
      box-shadow: none !important;
      top: auto;
      right: auto;
      left: auto;
      @media only screen and (max-width: 768px) {
        width: 100%;  
      }
      .row {
        &:after {
          display: none;
        }
        &-contents {
          &:before {

          }
        }
        &-info {
          padding-left: 0;
          color: $loloft-sand;
          margin-top: -15px;
        }
      }
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    h4 {
      margin: 0;
      padding: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    &.ui {
      &.popup {
        position: fixed !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0;
        width: auto;
        top: auto !important;
        transform: none !important;
        -webkit-transform-origin: unset !important;
        transform-origin: unset !important;
        max-width: 100%;
      }
    }
  }
}
