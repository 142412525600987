@import "./colors.scss";

.mapSizeViewFilter {
  height: 608px !important;
  width: 100% !important;
  left: 0 !important;
}

.mapSizeDetail {
  height: 380px !important;
  width: 100% !important;
  left: 0 !important;
}
.mapMarker {
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: $white;
  top: -28px;
  left: -28px;
  .map-label{
    padding: 6px;
  }
  &::after {
    content: "";
    display: block;
    border-width: 8px 8px 0;
    border-style: solid;
    border-color: transparent;
    margin-left: -8px;
    position: absolute;
    left: 50%;
    bottom: -7px;
  }
  &:hover {
    cursor: pointer;
  }
  &.mapMarkerBlack {
    background-color: $black;
    &::after {
      border-color: $black transparent transparent;
    }
  }
  &.mapMarkerYellow {
    background-color: $goldenYellow;
    &::after {
      border-color: $goldenYellow transparent transparent;
    }
  }
}

.mapMakerIcon {
  width: 39px !important;
  height: 49px !important;
  background-size: 39px 49px !important;
  position: relative;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -48px;
  left: -14px;
  background: url("/assets/icons/icon-marker.svg") no-repeat;
}

.mapInput {
  display: grid;
  grid-template-columns: 45px 1fr 30px;
  grid-template-rows: 1fr;
  height: 50px;
  position: relative;
  top: 2px;
  left: 0;
  width: 100%;
  background-color: white;
  // box-shadow: 0px 10px 20px rgba(183, 192, 213, 0.15);
  z-index: 1;
  .predictions {
    position: absolute;
    top: 50px;
    width: 100%;
    left: 0;
  }
}
.background-dialog-none{
  .MuiDialog-paperFullWidth {
    width: 100% !important;
  }
  .MuiBackdrop-root{
    background-color:transparent;
  }
}

/* DESKTOP OWNER MAP*/
.desk-owner-map{
  width: 100%;
  height: 100vh;
}
.mapMarkerImage{
  width: 70px;
  height: 70px;
  background-size: 70px 70px;
}