@import "loloft.scss";

.loloft {
  &-browse {
    .loloft {
      &-map {
        margin-top: -1rem !important;
        h4 {
          background: $loloft-dark;
          box-shadow: $loloft-box-shadow;
          padding: 5px 10px;
          font-size: $loloft-fs;
          color: $loloft-white;
          position: relative;
          width: 70px;
          cursor: pointer;
          &:after {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba($loloft-dark, 0);
            border-top-color: $loloft-dark;
            border-width: 6px;
            margin-left: -6px;
          }
          &:hover {
            background: $loloft-yellow;
            color: $loloft-dark;
            z-index: 98000;
            &:after {
              border-color: rgba($loloft-yellow, 0);
              border-top-color: $loloft-yellow;
              border-width: 6px;
              margin-left: -6px;
            }
          }
        }
        &-trigger {
          position: relative;
          display: none !important;
          @media only screen and (max-width: 768px) {
            display: flex !important;
          }
          &:before, &:after {
            position: absolute;
            transform: translate(-50%, -50%);
            transform-origin: 50% 50%;
            transition: $loloft-transition;
          }
          &:before {
            content: '';
            background: url("/assets/icons/icon-map.svg") 50% no-repeat !important;
            width: 18px;
            height: 18px;
            background-size: contain !important;
            transform: rotate(0deg);
          }
          &:after {
            content: '';
            background: url("/assets/icons/icon-close.svg") 50% no-repeat !important;
            width: 14px;
            height: 14px;
            background-size: contain !important;
            opacity: 0;
            transform-origin: 50% 50%;
            transform: rotate(-45deg);
          }
          &.active {
            background: none !important;
            &:before {
              transform: rotate(0);
              opacity: 0;
            }
            &:after {
              transform: rotate(0);
              opacity: .5;
            }
          }
        }
      }
    }
    .navbar {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 900;
    }
    .ui {
      &.grid {
        .lowered {
          padding-top: 166px;
          @media only screen and (max-width: 768px) {
            padding-top: 146px !important;
          }
        }
        .column {
          &.wide {
            &.seven {
              &.loloft {
                &-map {
                  position: fixed;
                  right: -1rem;
                  top: 150px;
                  bottom: 0;
                  width: 43.75%!important;
                  @media only screen and (max-width: 768px) {
                    top: 900px;
                    bottom: auto;
                    right: 0;
                    left: 0;
                    width: auto !important;
                    transition: $loloft-transition;
                    &.active {
                      top: 146px;
                      bottom: 0;
                      padding-bottom: 0.5rem !important;
                    }
                  }
                }
              } // map
            }
          }
        }
        &.variable {
          .column {
            &.wide {
              &.three {
                @media only screen and (max-width: 1400px) {
                  width: 50% !important;
                }

                @media only screen and (max-width: 460px) {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
    }
    .list {
      display: grid;
      grid-column-gap: 25px;
      grid-row-gap: 0;
      grid-template-columns: 1fr 1fr 1fr;
      @media only screen and (max-width: 1700px) {
        grid-template-columns: 1fr 1fr;
      }
      @media only screen and (max-width: 968px) {
        grid-template-columns: 1fr;
        padding: 15px;
      }
      @media only screen and (max-width: 768px) {
        & + .more {
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    }
    footer {
      &.loloft {
        &-footer {
          width: 56.25%!important;
          padding-left: 2rem;
          padding-right: 2rem;
          @media only screen and (max-width: 768px) {
            width: 100%!important;
            padding-left: 0;
            padding-right: 0;
          }
          .column {
            display: none;
            &.full {
              display: block;
            }
          }
        }
      }
    }
  }
}

.browse {
  &.headline {
    background: $loloft-white;
    box-shadow: $loloft-box-shadow;
    padding: 70px 25px 15px;
    z-index: 90;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    @media only screen and (max-width: 768px) {
      box-shadow: $loloft-dropdown-shadow;
      flex-direction: column;
      margin-left: 0;
      padding-top: 55px;
    }
    .ui {
      &.search {
        width: 400px;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
        input {
          font-size: $loloft-e;
          padding-left: 35px !important;
        }
        .icon {
          &.search {
            width: 20px;
            &:before {
              content: '';
              width: 20px;
              height: 20px;
              background: url("/assets/icons/icon-search.svg") 50% no-repeat !important;
              background-size: contain !important;
              margin-top: -11px;
            }
          }
        }
      }
      &.button {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: $loloft-pale;
        transition: $loloft-transition;
        font-weight: 400;
        font-size: $loloft-sm;
        color: $loloft-dark;
        margin: 0 0 0 5px;
        @media only screen and (max-width: 768px) {
          margin: 0 5px 0 0;
          padding: 8px 15px;
        }
        img {
          opacity: .5;
          margin-right: 10px;
          transition: $loloft-transition;
        }
        &:hover {
          img {
            opacity: 1;
          }
        }
        &.single {
          justify-content: center;
          img {
            margin: 0;
          }
        }
      }
    }
    .actions {
      display: flex;
      flex-direction: row;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      .ui {
        &.button {
          @media only screen and (max-width: 768px) {
            display: flex;
            flex: 1;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
.bp {
  &-small {
    
  }
  &-medium {
    
  }
  &-large {
    
  }
  &-wide {
    
  }
}
