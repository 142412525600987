.loloft-button-primary 
{
    height: 50px;
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%;
    color: black;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    background: linear-gradient(0deg, #FFC318, #FFC318), linear-gradient(0deg, #5367FF, #5367FF), #0049FF;
}
.loloft-button-secondary {
    background: linear-gradient(0deg, #85888C, #85888C), #8993B8;
    height: 50px;
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
}
.card-overlayed-mobile {
    height: 100%;
    width: 100%;
    position: relative;
    img {
    width: 100%;
    height: 60%;
    object-fit: cover;
    }
  .image-broken-heart{
    margin: 17px 0 20px;
  }
  .message {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 9px;
  }
  .title{
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 36px !important;
    line-height: 115.6%;
    margin-bottom: 13px;
  }
  .text{
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 23px;
    @media (max-width: 768px) {
      
    }
    margin-bottom: 35px;
  }
  .button-container {
    margin-bottom: 20px;
  }
}
.card-overlay {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(50.72%, #f3f4f9), to(rgba(243, 244, 249, 0)));
    background: linear-gradient(0deg, #f3f4f9 44.72%, rgba(243, 244, 249, 0) 80%);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 9;
    .content {
        bottom: 0px;
        left: 0;
    right: 0;
        position: absolute;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 85vw;
        width: 100%;
    }
}
.loloft-nav {
  transition: 0.5s ease-in-out;
  box-shadow: unset;
  background-color: white;  
  &.nav-shadow {
    box-shadow: 0px 3px 20px -10px black;
    background-color: white;
  }
  &.nav-transparent {
    background-color: transparent;
    @media (min-width: 768px) {
      div {
        color: white !important;
      } 
    }
  }
}
