@import "loloft.scss";

.loloft {
  &-onboarding {
    .box {
      &.basic {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        @media only screen and (max-width: 768px) {
          margin-top: -100px;
          position: relative;
          z-index: 90;
          padding: 35px 10px 0;
          .box {
            &.basic {
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
    .loloft-progress {
      margin-bottom: 30px;
      margin-left: 60px;
      @media only screen and (max-width: 768px) {
        position: absolute;
        left: 10px;
        right: 10px;
        margin-left: 0;
        margin-bottom: 0;
        top: 0;
        display: flex;
        flex-direction: column-reverse;
        .ui {
          &.progress {
            background: $loloft-pale;
            height: 5px;
            margin-bottom: 5px;
            .bar {
              height: 5px;
            }
            span {
              font-weight: 400;
            }
          }
        }
      }
    }
    .action-row {
      padding-bottom: 10px;
      .indicator {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: $loloft-white;
        box-shadow: $loloft-dropdown-shadow;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: $loloft-sand;
        margin-right: 25px;
        font-size: $loloft-fs;
        line-height: 1;
        flex-shrink: 0;
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      h2 {
        font-size: $loloft-t;
        font-weight: 400;
        color: $loloft-sand;
        padding-bottom: 20px;
        margin-bottom: 0;
        padding-top: 5px;
        @media only screen and (max-width: 768px) {
          padding-bottom: 40px; 
        }
      }
      .contents {
        width: 100%;
        .ui {
          &.button {
            margin-top: 20px;
          }
        }
        .incremented {
          .ui {
            &.button {
              margin-top: 0;
            }
          }
          &.contents {
            width: auto;
          }
        }
      }
      &.active {
        padding-bottom: 40px;
        .indicator {
          background: $loloft-yellow;
          color: $loloft-dark;
        }
        h2 {
          font-weight: 700;
          color: $loloft-dark;
        }
      }
      &.done {
        padding-bottom: 40px;
        h2 {
          padding-bottom: 5px;
        }
        a {
          color: $loloft-dark;
          margin-left: 15px;
        }
        .indicator {
          font-size: 0;
          &:after {
            display: block;
            content: '';
            background: url("/assets/icons/icon-check.svg") 50% no-repeat;
            background-size: contain;
            width: 20px;
            height: 20px;
            position: relative;
            top: 2px;
            left: 0;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        display: none;
        &.active {
          display: block;
        }
      }
      .ui {
        &.grid {
          &.straight {
            margin: 10px -5px;
            .column {
              padding: 5px;
              .contents {
                height: 100px;
              }
              .card {
                &.upload {
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .ui {
        &.grid {
          &.stackable {
            .column {
              &.login-bg {
                background: $loloft-white;
                box-shadow: $loloft-dropdown-shadow !important;
                height: 170px;
                padding: 25px !important;
              }
            }
          }
        }
      }
    }
    .field {
      .StripeElement {
        padding: 5px 15px 10px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {

}
