@import "loloft.scss";

.loloft {
  &-footer {
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: $loloft-fs;
    color: rgba($loloft-dark, .7);
    @media only screen and (max-width: 768px) {
      padding-top: 10px;
    }
    .contents {
      display: flex;
      flex-wrap: wrap;
      .column {
        flex: 0 0 22%;
        &.full {
          flex: 0 0 100%;
          text-align: center;
        }
        &.wide {
          flex: 0 0 33.333%;
        }
        .item {
          font-size: $loloft-fs;
          padding: 6px 0;
          a {
            color: rgba($loloft-dark, .7);
            padding: 5px 0;
            &:hover {
              color: $loloft-dark;
            }
          }
        }
        h4 {
          font-weight: 700;
          color: $loloft-dark;
        }
      }
    }
    .terms {
      color: $loloft-sand;
      padding: 15px 0;
      a {
        color: rgba($loloft-dark, .7);
      }
      span {
        margin-left: 8px;
        margin-right: 8px;
      }
      strong {
        &.highlight {
          a {
            color: $loloft-dark;
          }
        }
      }
    }
    .mobile {
      display: none;
      @media only screen and (max-width: 768px) {
        display: block;
        padding: 20px 20px 10px 25px;
      }
      padding: 20px 20px 10px;
      box-shadow: $loloft-box-shadow;
      background: $loloft-white;
      margin-bottom: -20px;
      .terms, .copyright {
        font-size: $loloft-sm;
      }
      .copyright {
        color: $loloft-dark;
      }
      .ui {
        &.input {
          &.action {
            margin-bottom: 20px;
            box-shadow: none;
            input {
              background: $loloft-pale;
            }
          }
        }
      }
      h4 {
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 18px;
      }
    }
    .default {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

