@import "loloft.scss";

.carousell {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

.pagination {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}
.dot {
  background-color: rgba($loloft-dark, .4);
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 4px solid transparent;
  margin-left: auto;
  margin-right: auto;
  transition: $loloft-transition;
  &-button {
    height: 12px;
    width: 12px;
    cursor: pointer;
    border: 0;
    background: none;
    padding: 0;
    outline: none;
    margin-left: 2px;
    margin-right: 2px;
  }
  &-active {
    border: 0;
    width: 12px;
    height: 12px;
    background-color: $loloft-color-active;
  }
}