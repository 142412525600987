@import "loloft.scss";

.dropdown {
  &-with-button {
    &--dropdown {
      position: relative;
      .sm-label {
        position: absolute;
        left: 0;
        font-weight: 700;
        top: 9px;
        left: 20px;
        z-index: 90;
        font-size: $loloft-sm;
        color: $loloft-dark;
      }
      .ui {
        &.dropdown {
          &.active {
            & ~ .sm-label {
              color: rgba($loloft-dark, .5);
            }
          }
        }
      }
    }
    &--button {
      margin-top: 10px;
    }
  }
}