@keyframes slide-up {
  0% {
    top: 5px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: 25px;
  }
}
@keyframes dissapear {
  0% {
    opacity: 1;
    top: 25px;
  }
  100% {
    top: 5px;
    opacity: 0;
  }
}
