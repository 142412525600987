@import "loloft.scss";

.navbar {
  .ui {
    &.menu {
      font-family: $loloft-font;
      background: transparent;
      border: 0;
      box-shadow: none;
      .item {
        transition: $loloft-transition;
        a {
          color: $loloft-sand;
          font-size: $loloft-fs;
          transition: $loloft-transition;
          &:hover {
            color: $loloft-dark;
          }
        }
        &.active {
          a {
            color: $loloft-dark;
            opacity: 1;
          }
        }
        &:before {
          content: '';
          transition: $loloft-transition;
          display: block;
          position: absolute;
          width: 100%;
          left: 0;
          top: -4px;
          height: 4px;
          background: $loloft-yellow;
        }
        &:hover {
          color: $loloft-dark;
          &:before {
            top: 0;
          }
        }
        .image {
          &.avatar {
            margin-left: 15px;
          }
        }
      }
    }
  }
  .mobile {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
    .ui {
      &.menu {
        .item {
          &.left {
            padding: 20px;
          }
          .logo {
            width: 75px;
          }
          .sidebar {
            &.icon {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .default {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}
