$black: rgb(28, 25, 25);
$goldenYellow: rgb(255, 195, 24);
$paleGrey: rgb(249, 250, 253);
$blueGrey: rgb(137, 147, 184);
$blueGrey30: rgba(137, 147, 184, 0.3);
$white: rgb(255, 255, 255);
$loloft-color: rgb(255, 195, 24);
$more-options: rgb(164, 172, 203);
$amenities: rgb(241, 242, 248);
$steelGrey: rgb(133, 136, 140);
$loloftPale: rgb(243, 244, 249);
$loloftRed: rgb(232, 73, 63);
$loloftGreen : rgb(95,206,85);
$grey: #85888C;
.backCoYe {
  background-color: $goldenYellow !important;
}
.backCoTransparente {
  background-color: rgba(0, 0, 0, 0) !important;
}
.backCoPG {
  background-color: $paleGrey !important;
}
.backCoBG {
  background-color: $blueGrey !important;
}
.backCoWh {
  background-color: $white !important;
}
.colorGrey {
  color: $grey !important;
}
.colorWh {
  color: $white !important;
}
.colorYe {
  color: $goldenYellow !important;
}
.colorPG {
  color: $paleGrey !important;
}
.colorBG {
  color: $blueGrey !important;
}
.colorBG30 {
  color: $blueGrey30 !important;
}
.colorBl {
  color: $black !important;
}
.colorSG {
  color: $steelGrey !important;
}
.colorRed {
  color: $loloftRed !important;
}
.opacityIcons {
  opacity: 30%;
}
