@import "loloft.scss";
.loloft {
  &-home {
    .navbar {
      .ui {
        &.menu {
          .item {
            a {
              color: $loloft-white;
            }
          }
        }
      }
    }
  }
  &-hero {
    background: url("/assets/backgrounds/bg-bed-people.jpg") 50% no-repeat;
    background-size: cover;
    text-align: center;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 700px;
    position: relative;
    @media only screen and (max-width: 768px) {
      height: 440px;
      padding: 20px;
    }
    &:before {
      content: '';
      background: rgba($loloft-dark, .2);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
    }
    .ui {
      &.container {
        &.text {
          position: relative;
          z-index: 11;
          @media only screen and (max-width: 768px) {
            width: 100% !important;
          }
        }
      }
    }
    h1 {
      color: $loloft-white;
      padding-bottom: 20px;
      @media only screen and (max-width: 768px) {
        font-size: 30px;
      }
    }
    .dropdown {
      &-with-button {
        max-width: 325px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
.commitment {
  &-card {
    background: $loloft-color-white;
    box-shadow: $loloft-box-shadow;
    height: 100%;
    text-align: center;
    padding-bottom: 20px;
    @media only screen and (max-width: 768px) {
    }
    h3 {
      font-size: $loloft-t;
      padding: 0;
    }
    p {
      padding: 0 20px 20px;
      @media only screen and (max-width: 768px) {
        padding-bottom: 40px;
      }
    }
    .info {
      font-size: $loloft-sm;
      color: rgba($loloft-dark, .5);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .image {
        margin-left: 10px;
        position: relative;
        top: -1px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .ui {
      &.container {
        &.aligned {
          &.center {
            margin-left: 0 !important;
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}
.locations {
  background: $loloft-white;
  box-shadow: $loloft-box-shadow;
  @media only screen and (max-width: 768px) {
    background: transparent;
    box-shadow: none;
  }
  &.section {
    padding: 0;
    @media only screen and (max-width: 768px) {
      text-align: center;
    }
  }
  .ui {
    &.menu {
      &.vertical {
        box-shadow: none;
        margin: 0 0 30px;
        border: 0;
        background: none;
        @media only screen and (max-width: 768px) {
          display: flex;
          flex-direction: row;
          margin-bottom: -20px;
        }
        .item {
          font-size: $loloft-t;
          color: rgba($loloft-dark, .7);
          border-radius: 0;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          display: flex;
          justify-content: flex-start;
          &:before {
            display: none;
          }
          @media only screen and (max-width: 768px) {
            font-size: $loloft-fs;
            box-shadow: $loloft-dropdown-shadow;
            background: $loloft-white;
            margin: 0 5px;
            flex: auto;
            padding: 10px 15px;
            flex-shrink: 0;
            justify-content: center;
          }
          &.active {
            background: $loloft-dark;
            color: $loloft-white;
            border: 0;
            border-radius: 0;
            &:after {
              left: 100%;
              top: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba($loloft-dark, 0);
              border-left-color: $loloft-dark;
              border-width: 34px;
              margin-top: -34px;
            }
            @media only screen and (max-width: 768px) {
              background: $loloft-yellow;
              color: $loloft-dark;
              margin: 0 5px;
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
    &.grid {
      margin: 0;
      .column {
        padding: 0;
        &.six {
          position: relative;
          z-index: 90;
          @media only screen and (max-width: 768px) {
            position: static;
          }
        }
        &.ten {
          @media only screen and (max-width: 768px) {
            position: relative;
            &:after {
              content: '';
              position: absolute;
              left: -20px;
              right: -20px;
              top: 13px;
              height: 180px;
              background: linear-gradient(180deg, #F3F4F9 0%, rgba(243, 244, 249, 0) 93.35%);
            }
          }
        }
      }
    }
    &.container {
      .tab {
        margin-left: -1em;
        margin-right: -1em;
      }
      @media only screen and (max-width: 768px) {
        position: relative;
      }
    }
  }
  .pad {
    &.right {
      padding-right: 70px;
      @media only screen and (max-width: 768px) {
        padding-right: 0;
      }
    }
  }
  .dropdown {
    &-with-button {
      @media only screen and (max-width: 768px) {
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 40px;
      }
    }
  }
}
.property {
  &-owners {
    &-panel {
      overflow: hidden;
      text-align: center;
      padding-bottom: 50px;
      @media only screen and (max-width: 768px) {
        p {
          padding-left: 20px;
          padding-right: 20px;
        }
        .ui {
          &.container {
            &.aligned {
              &.center {
                margin-left: 0 !important;
                margin-right: 0 !important;
              }
            }
          }
        }
      }
      &--button-wrapper {
        padding-top: 40px;
        width: 325px;
        @media only screen and (max-width: 768px) {
          padding-top: 0px;
        }
      }
    }
    &-item {
      padding-top: 50px;
      text-align: center;
      @media only screen and (max-width: 768px) {
        padding-top: 20px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
      }
      &--plus {
        padding-top: 110px;
      }
    }
  }
}
