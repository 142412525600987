@import "loloft.scss";

.loloft {
  &-auth,
  &-onboarding {
    &.pushable {
      background: $loloft-pale !important;
    }
    .pusher,
    .page-content {
      height: 100vh;
      @media only screen and (max-width: 768px) {
        height: auto;
      }
    }
    .ui {
      &.grid:not(.straight) {
        height: 100vh;
        @media only screen and (max-width: 768px) {
          height: auto;
        }
      }
      &.segment {
        border: 0;
        padding: 0;
        box-shadow: none;
        background: none;
      }
      &.menu {
        &.secondary {
          display: flex;
          flex-direction: row;
          margin-bottom: 25px;
          .item {
            flex: auto;
            justify-content: center;
            border-radius: 50px;
            background: $loloft-white;
            box-shadow: $loloft-dropdown-shadow;
            font-size: $loloft-fs;
            font-weight: 700;
            &.active {
              background: $loloft-yellow;
            }
          }
        }
      }
    }
    .box {
      &.basic {
        max-width: 325px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    h1 {
      font-size: 30px;
      padding: 0 0 20px;
      margin: 0;
      font-weight: 700;
      @media only screen and (max-width: 768px) {
        font-size: $loloft-t;
      }
    }
    .login-bg {
      background: url("/assets/img/auth-bg.jpg") 50% no-repeat;
      background-size: cover;
      @media only screen and (max-width: 768px) {
        height: 300px;
      }
    }
    .navbar {
      .ui {
        &.menu {
          .item:not(.permanent) {
            display: none;
          }
        }
      }
    }
  }
}